import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './assets/scss/templateStyles/style.scss';
import './assets/scss/custom.scss';
import './assets/scss/customStyles/react-table.css';
import './assets/scss/customStyles/style.scss';
import { Provider } from 'react-redux';
import store from './redux/Store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './i18n';
import { CustomKeyboard } from './components/customKeyboard/CustomKeyboard';

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./app')), 0);
    }),
);
const queryClient = new QueryClient();

const loadIconStyles = async () => {
  const stylesheets = [
    () => import('./assets/scss/templateStyles/all/icons/font-awesome/css/fontawesome-all.css'),
    () => import('./assets/scss/templateStyles/all/icons/simple-line-icons/css/simple-line-icons.css'),
    () => import('./assets/scss/templateStyles/all/icons/weather-icons/css/weather-icons.css'),
    () => import('./assets/scss/templateStyles/all/icons/themify-icons/themify-icons.css'),
    () => import('./assets/scss/templateStyles/all/icons/flag-icon-css/flag-icon.min.css'),
    () => import('./assets/scss/templateStyles/all/icons/material-design-iconic-font/css/materialdesignicons.min.css'),
    () => import('./assets/scss/templateStyles/all/icons/crypto-icons/cryptocoins.css'),
  ];

  const retry = async (fn, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        console.warn(`Retry ${i + 1} for loading stylesheet failed:`, error.message);
        if (i < retries - 1) await new Promise((res) => setTimeout(res, delay));
      }
    }
    throw new Error('Failed to load stylesheets after multiple retries');
  };

  for (const loadStylesheet of stylesheets) {
    await retry(
      () =>
        new Promise((resolve, reject) => {
          loadStylesheet()
            .then(resolve)
            .catch((error) => reject(new Error(`Failed to load stylesheet: ${error.message}`)));
        }),
    );
    console.log('Loaded stylesheet successfully.');
  }
};

const bootstrapApp = async () => {
  try {
    await loadIconStyles();
    console.log('All stylesheets loaded successfully.');
  } catch (error) {
    console.error('Error loading stylesheets:', error.message);
    return;
  }

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <CustomKeyboard />
          <App />
        </QueryClientProvider>
      </Provider>
    </>,
  );
};

bootstrapApp();
