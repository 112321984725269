import { JwtPayload } from 'jwt-decode';
import { DeviceType } from '../../types/device/deviceType';
import { MachineType } from '../../views/machines/types/MachineType';
import { DevicesReducerType, StoreAction } from '../redux.interface';

export const setAllDevices = (devices: DeviceType[]) => ({
  type: 'devices/SET_ALL_DEVICES',
  payload: devices,
});
export const setAllMachines = (machines: MachineType[]) => ({
  type: 'devices/SET_ALL_MACHINES',
  payload: machines,
});

export const resetAllDevices = () => ({
  type: 'devices/RESET_ALL_DEVICES',
});
export const resetAllMachines = () => ({
  type: 'devices/RESET_ALL_MACHINES',
});
export const setCurrentDevice = (currentDevice: DeviceType) => ({
  type: 'devices/SET_CURRENT_DEVICE',
  payload: currentDevice,
});

export const resetCurrentDevice = () => ({
  type: 'devices/RESET_CURRENT_DEVICE',
});

export const setParentDevice = (parentDevice: DeviceType) => ({
  type: 'devices/SET_PARENT_DEVICE',
  payload: parentDevice,
});
export const resetParentDevice = () => ({
  type: 'devices/RESET_PARENT_DEVICE',
});
export const setSerialNumbers = (payload: string) => ({
  type: 'devices/SET_SERIAL_NUMBER',
  payload,
});

const initialState: DevicesReducerType = {
  allDevices: null,
  allMachines: null,
  currentDevice: null,
  parentDevice: null,
  serialNumber: '',
};

export default function specificRulesReducer(state = initialState, action: StoreAction) {
  switch (action.type) {
    case 'devices/SET_ALL_DEVICES':
      const allDevices = action.payload;
      return {
        ...state,
        allDevices,
      };
    case 'devices/RESET_ALL_DEVICES':
      return {
        ...state,
        allDevices: initialState.allDevices,
      };
    case 'devices/SET_ALL_MACHINES':
      const allMachines = action.payload;
      return {
        ...state,
        allMachines,
      };
    case 'devices/RESET_ALL_MACHINES':
      return {
        ...state,
        allMachines: initialState.allMachines,
      };

    case 'devices/SET_CURRENT_DEVICE':
      const currentDevice = action.payload;
      return {
        ...state,
        currentDevice,
      };
    case 'devices/RESET_CURRENT_DEVICE':
      return {
        ...state,
        currentDevice: initialState.currentDevice,
      };
    case 'devices/SET_PARENT_DEVICE':
      const parentDevice = action.payload;
      return {
        ...state,
        parentDevice,
      };
    case 'devices/RESET_PARENT_DEVICE':
      return {
        ...state,
        allDevices: initialState.parentDevice,
      };
    case 'devices/SET_SERIAL_NUMBER':
      return {
        ...state,
        serialNumber: action.payload,
      };
    default:
      return state;
  }
}
